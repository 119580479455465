@tailwind base;
@tailwind components;
@tailwind utilities;

.sqs-block-button-element {
  box-shadow: 5px 5px #000;
  border: 2px solid #000 !important;
  transition: all ease-in 0.2s !important;
}

.sqs-block-button-element:hover {
  box-shadow: none;
  transform: translateY(4px) !important;
  transition: all ease-in 0.2s;
  opacity: 1 !important;
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 5.5s steps(60, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

/* In your CSS or Tailwind CSS configuration */
.btn {
  width: 120px; /* Set your desired fixed width */
  padding: 10px; /* Adjust padding as needed */
  text-align: center;
}
